import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import ApiEndPoints from "../Api/Apiendpoints";
import { allProducts, deleteBanner } from "../Api/Apicalls";
import DOMPurify from "dompurify";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { Spin } from "antd";

interface Data {
  tagName: string;
  tagContent: string;
  metaTitle: string;
  metaContent: string;
  _id: any;
}

const Tags = () => {
  const [data, setData] = useState<Data[]>([]);

  const getAllTags = async () => {
    try {
      const response = await allProducts(ApiEndPoints("addtags"));
      setData(response.productTags);
    } catch (error) {
      console.error(error);
    }
  };

  const handleTagsdelete = async (id: any) => {
    try {
      const response = await deleteBanner(ApiEndPoints("addtags"), id);
      await getAllTags();
    } catch (error) {
      console.error(error);
    }
  };
  const navigate = useNavigate();
  const handleEdittag = (id: any) => {
    navigate(`/edit-tags/${id}`);
  };

  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <>
      <section className="py-2">
        <Container>
          <div className="text-end">
            <Link to="/add-tags">
              <Button variant="contained" className="addtagbtn">
                Add new
              </Button>
            </Link>
          </div>
          <div className="py-3">
            {data.length>0?
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 700 }}>S.No</TableCell>
                    <TableCell style={{ fontWeight: 700 }}>Tag Name</TableCell>
                    <TableCell style={{ fontWeight: 700 }}>Content</TableCell>
                    <TableCell style={{ fontWeight: 700 }}>
                      Meta Title
                    </TableCell>
                    <TableCell style={{ fontWeight: 700 }}>
                      Meta Content
                    </TableCell>
                    <TableCell style={{ fontWeight: 700 }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, i) => {
                    const sanitizedContent = DOMPurify.sanitize(
                      row.tagContent || ""
                    );

                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {i + 1}
                        </TableCell>
                        <TableCell>{row.tagName}</TableCell>
                        <TableCell>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                sanitizedContent.slice(0, 100) +
                                (sanitizedContent.length > 100 ? "..." : ""),
                            }}
                            style={{ fontSize: "15px", lineHeight: "1.5" }}
                          />
                        </TableCell>
                        <TableCell>{row.metaTitle}</TableCell>
                        <TableCell>{row.metaContent}</TableCell>
                        <TableCell>
                          <FaEdit
                            onClick={() => handleEdittag(row._id)}
                            className="me-2"
                            style={{
                              color: "green",
                              fontSize: "18px",
                              cursor: "pointer",
                            }}
                          />
                          <MdDeleteForever
                            style={{
                              color: "red",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleTagsdelete(row._id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>:<><div style={{height:"70vh",display:"flex",justifyContent:"center",alignItems:"center"}} className="text-center"><Spin/></div></>}
          </div>
        </Container>
      </section>
    </>
  );
};

export default Tags;
