import { Table, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import MuiButton from "@mui/material/Button";
import { allCustomer, allCustomorder, updateCoupon } from "../Api/Apicalls";
import ApiEndPoints from "../Api/Apiendpoints";
import { API_URL } from "../Api/Config";
import { FaDownload } from "react-icons/fa6";

const { Dragger } = Upload;

const props: UploadProps = {
  name: "file",
  multiple: true,
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid white",
  BorderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const CustomOrder = () => {
  const [open, setOpen] = useState(false);
  const [isUpload, setIsupload] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [isdec, setIsdes] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenupload = () => setIsupload(true);
  const handleCloseupload = () => setIsupload(false);
  const [price, setPrice] = useState("");
  const [filename, setFileName] = useState("");

  const handleOpend = (description: string) => {
    setSelectedDescription(description);
    setIsdes(true);
  };
  const handleClosed = () => setIsdes(false);

  const handleDownload = async (fileUrl: string) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", "downloaded_file");
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file", error);
      message.error("Error downloading the file");
    }
  };

  // Handle form input changes

  const [selectedProduct, setSelectedProduct] = useState<any>(null);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setSelectedProduct({ ...selectedProduct, [name]: value });
  };

  const [emailMap, setEmailMap] = useState<Record<string, string>>({});

  // API CALLING

  useEffect(() => {
    fetchCustomorder();
  }, []);

  const fetchCustomorder = async () => {
    try {
      const firstresponse = await allCustomorder(
        ApiEndPoints("getCustomorder")
      );

      const response = await allCustomer(ApiEndPoints("getCustomers"));

      const data = response.users;
      // Create a mapping of userId to email
      const emailMapping = data.reduce(
        (acc: Record<string, string>, user: any) => {
          acc[user._id] = user.name;
          return acc;
        },
        {}
      );

      // Save the email mapping to state
      setEmailMap(emailMapping);
      let sortedData = firstresponse.customOrders.reverse()
      setProductDatas(sortedData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMarkAsCompleted = async (orderId: string) => {
    try {
      // Implement API call to mark order as completed
      // For example: await updateOrderStatus(orderId, "Completed");
      message.success("Order marked as completed.");
      // Fetch updated orders
      fetchCustomorder();
    } catch (error) {
      message.error("Failed to mark order as completed.");
    }
  };

  const handleRejectOrder = async (orderId: string) => {
    try {
      message.success("Order rejected.");
      fetchCustomorder();
    } catch (error) {
      message.error("Failed to reject order.");
    }
  };

  const handleOpenUpload = (orderId: string) => {
    setSelectedProduct({ ...selectedProduct, orderId });
    handleOpenupload();
  };

  const handleOpenSendQuotation = async (orderId: string) => {
    setSelectedProduct({ ...selectedProduct, orderId });
    handleOpen();
  };
  const handleUpdatePrice = async () => {
    if (price === "") {
      message.error("Please enter the amount!");
    } else {
      const formData = new FormData();
      formData.append("quoteAmount", price);
      try {
        const response = await updateCoupon(
          ApiEndPoints("getCustomorder"),
          selectedProduct.orderId,
          formData
        );
        message.success("Amount updated!");
        setOpen(false);
        await fetchCustomorder();
      } catch (err) {
        console.error("Error updating coupon:", err);
        message.error("An error occurred while updating the coupon.");
      }
    }
  };

  const [productDatas, setProductDatas] = useState<any[]>([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "user",
      render: (userId: string) => <span>{emailMap[userId]}</span>,
    },
    {
      title: "Order Id",
      dataIndex: "_id",
      render: (_id: string) => <span>{_id.slice(-5)}</span>,
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (imageUrl: string) => (
        <>
          <FaDownload
            style={{ cursor: "pointer" }}
            onClick={() => handleDownload(`${API_URL}/public/images/${imageUrl}`)}
          />
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text: string, _record: any) => (
        <span
          style={{ cursor: "pointer" }}
          className="quotation"
          onClick={() => handleOpend(text)}
        >
          Description
        </span>
      ),
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Price",
      dataIndex: "quoteAmount",
      render: (text: string, _record: any) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "Action",
      dataIndex: "isPaid",
      render: (status: string, record: any) => (
        <div className="">
          {!record.quoteAmount ? (
            // If no price is updated yet, show 'Send Quotation'
            <Button onClick={() => handleOpenSendQuotation(record._id)}>
              Send Quotation
            </Button>
          ) : !record.isPaid ? (
            // If price is updated but not paid yet, show 'Pending'
            <Button disabled>Pending</Button>
          ) : record.zip === null ? (
            // If price is paid but no file uploaded yet, show 'Upload'
            <Button onClick={() => handleOpenUpload(record._id)}>Upload</Button>
          ) :(
            // If file is uploaded (zip is not empty), show 'Completed'
            <Button>Completed</Button>
          ) }
        </div>
      ),
    },
  ];
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file: any) => {
      console.log(file, "file");
      setSelectedFile(file);
      setFileName(file.name);
      return false;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        setFileName(info.file.name);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleFileupload = async () => {
    console.log(selectedFile);
    if (!selectedFile) {
      message.error("No file selected for upload.");
      return;
    }
    const formData = new FormData();
    formData.append("category", "custom digitizing");
    formData.append("zip", selectedFile, "test.zip");

    try {
      const response = await updateCoupon(
        ApiEndPoints("getCustomorder"),
        selectedProduct.orderId,
        formData
      );
      message.success("File updated!");
      setIsupload(false);
      await fetchCustomorder();
    } catch (err) {
      console.error("Error updating coupon:", err);
      message.error("An error occurred while updating the file.");
    }
  };
  return (
    <>
      <section className="py-4">
        <div className="p-2">
          <h2 className="title">Custom Orders</h2>
        </div>
        <Container>
          <div className="table-responsive">
            {productDatas.length>0?
            <Table
              columns={columns}
              dataSource={productDatas}
              pagination={{ pageSize: 10 }}
            />:<><div style={{height:"70vh",display:"flex",justifyContent:"center",alignItems:"center"}} className="text-center"><Spin/></div></>}
          </div>
        </Container>
      </section>

      {/* send quotation */}

      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3 className="title-h3">Send Quotations</h3>
                </div>
                <div>
                  <CloseIcon
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div>
                <label className="label py-2">Price</label>
                <br />
                <input
                  style={{ width: "100%", border: "1px solid #000" }}
                  onChange={(e) => setPrice(e.target.value)}
                />
                <div className="text-center py-3">
                  <Button
                    variant="contained"
                    className="update-btn"
                    onClick={handleUpdatePrice}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      {/* Upload file */}

      <div>
        <Modal
          open={isUpload}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3 className="title-h3">Upload the file</h3>
                </div>
                <div>
                  <CloseIcon
                    onClick={handleCloseupload}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div>
                <label className="label py-2">Upload file</label>
                <br />
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <i
                      className="fi fi-sr-folder-upload"
                      style={{ color: "#FF518A", fontSize: "20px" }}
                    />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
                {filename && <span>Selected file:{filename}</span>}
                <div className="text-center py-3">
                  <Button
                    variant="contained"
                    className="update-btn"
                    onClick={handleFileupload}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      {/* Description file */}

      <div>
        <Modal
          open={isdec}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3 className="title-h3">Description</h3>
                </div>
                <div>
                  <CloseIcon
                    onClick={handleClosed}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div className="py-3">{selectedDescription}</div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default CustomOrder;
