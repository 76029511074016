import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Table, Input, Spin } from "antd";
import { allCustomer, allTransaction } from "../Api/Apicalls";
import ApiEndPoints from "../Api/Apiendpoints";
import { Button } from "@mui/material";

const Customers = () => {
  const [productDatas, setProductDatas] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [ogData, setOgData] = useState<any[]>([]);


  const fetchCustomersAndTransactions = async () => {
    try {
      const customerResponse = await allCustomer(ApiEndPoints("getCustomers"));
      const transactionResponse = await allTransaction(ApiEndPoints("allTransaction"));

      const customers = customerResponse.users;
      const transactions = transactionResponse.transactions;

      // Create a map to store the count of orders for each user
      const orderCountMap: { [key: string]: number } = {};

      // Iterate over each transaction to count orders for each user
      transactions.forEach((transaction:any) => {
        const userId = transaction.user;
        if (orderCountMap[userId]) {
          orderCountMap[userId] += 1;
        } else {
          orderCountMap[userId] = 1;
        }
      });

      // Map the order counts to the customers
      const customerData = customers.map((customer: any) => ({
        ...customer,
        orders: orderCountMap[customer._id] || 0, 

      }));

      setProductDatas(customerData);
      setFilteredData(customerData.reverse()); 
      setOgData(customerData)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCustomersAndTransactions();
  }, []);

  // Filter functionality
  interface FilterData {
    byCustomerName: string;
    byCustomerEmailId: string | null;
  }

  const [filterData, setFilterData] = useState<FilterData>({
    byCustomerName: "",
    byCustomerEmailId: null,
  });

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilterData((prevData) => ({
      ...prevData,
      [name]: value || null,
    }));
  };

  const handleFilter = () => {
    const { byCustomerEmailId, byCustomerName } = filterData;

    // Filter customers based on name and email
    const filteredCustomers = ogData.filter(
      (user) =>
        (!byCustomerEmailId || user.email === byCustomerEmailId.trim()) &&
        (!byCustomerName ||
          user.name.toLowerCase().includes(byCustomerName.toLowerCase()))
    );

    setFilteredData(filteredCustomers);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email Id",
      dataIndex: "email",
    },
    // {
    //   title: "Wallet Amount",
    //   dataIndex: "wallet",
    //   render: (wallet: number) => `$ ${wallet.toFixed()}`,
    // },
    {
      title: "No. of Orders",
      dataIndex: "orders",
    },
  ];

  return (
    <>
      <section className="py-4">
        <Container>
          <div>
            <div>
              <h2 className="title">Customers</h2>
            </div>
            <div className="row pb-4 py-4">
              <div className="col-lg-2 col-md-2 col-sm-4 col-4">
                <div>
                  <label className="label">Name</label>
                  <Input
                    className="input"
                    name="byCustomerName"
                    value={filterData.byCustomerName}
                    onChange={handleFilterChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4">
                <div>
                  <label className="label">Email Id</label>
                  <Input
                    className="input"
                    name="byCustomerEmailId"
                    value={filterData.byCustomerEmailId || ""}
                    onChange={handleFilterChange}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-4">
                <div>
                  <label style={{ visibility: "hidden" }}>""</label>
                  <br />
                  <Button
                    variant="contained"
                    className="btn-apply"
                    onClick={handleFilter}
                  >
                    Apply
                  </Button>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-end align-items-center">
                <div>
                  <h5 className="mb-0 mt-3">
                    Total Customers: {ogData.length}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            {filteredData.length>0?
            <Table
              columns={columns}
              dataSource={filteredData}
              pagination={{ pageSize: 10 }}
            />:<><div style={{height:"70vh",display:"flex",justifyContent:"center",alignItems:"center"}} className="text-center"><Spin/></div></>}
          </div>
        </Container>
      </section>
    </>
  );
};

export default Customers;
